<template>
    <div>
        <v-container fluid class="pt-0 mt-0">
            <HeaderAzul titulo="Alterar senha"/>
        </v-container>
        <v-row>
            <v-col cols="12">
                <v-card elevation="0">
                    <v-card-text>
                        <p class="text--primary">
                            Tome algumas precauções ao definir uma senha, um usuário malicioso ou não qualificado com acesso pode levar a resultados desastrosos,
                            portanto se deve tomar alguns cuidados ao escolher uma senha que não seja adivinhada facilmente. A senha não deve ser uma palavra encontrada em dicionário, ou que possa ser facilmente associada a você.
                        </p>
                        <p class="text--primary">Uma boa senha conterá uma mistura de letras, números e pontuação com no mínimo oito caracteres, e deverá ser modificada em intervalos regulares.</p>
                        <p class="text--primary">E principalmente, não compartilhe com ninguém.</p>
                        <v-form>
                            <v-row>
                                <v-col cols="12" class="pb-0"><v-text-field hide-details solo label="Senha atual:" type="password" v-model="registro.asenha" /></v-col>
                                <v-col cols="12" class="pb-0"><v-text-field autocomplete="new-password" hide-details solo label="Nova senha:" type="password" v-model="registro.nsenha" /></v-col>
                                <v-col cols="12" class="pb-0"><v-text-field hide-details solo label="Repita a nova senha:" type="password" v-model="registro.rsenha" /></v-col>
                                <v-col cols="12" class="pb-0 mt-3" v-if="erro"><v-alert dense type="error" v-html="erro"/></v-col>
                                <v-col cols="12" class="pb-0 mt-3" v-if="sucesso"><v-alert dense type="success" v-html="sucesso"/></v-col>
                                <v-col cols="12"><v-btn elevation="0" class="py-5" rounded dark @click="salvar" color="primary">Salvar</v-btn></v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import axios from "axios";
import {mapState} from "vuex";
import HeaderAzul from "@/Views/Estrutura/HeaderAzul";

export default {
    name: "ConfiguracaoSenhaAlterar",
    components: {HeaderAzul},
    data() {
        return {
            registro : {},
            erro : null,
            sucesso : null
        }
    },
    computed : {
        ...mapState(['apiUrl'])
    },
    methods : {
        salvar() {
            return axios.post(`${this.apiUrl}usuario/senha/alterar`, this.registro).then( () => {
                this.erro = null
                this.registro = {}
                this.sucesso = 'Senha alterada com sucesso'
            }).catch(e => {
                this.erro = this.erroFormatar(e)
            })
        },
        init() {
            this.registro = {}
            this.erro = null
            this.sucesso = null
        }
    },
    activated() {
        this.init()
    },
    created() {
        this.init()
    }
}
</script>

<style scoped>

</style>